import { LineMdCoffeeLoop } from '@/assets/icons/mine/LineMdCoffeeLoop';
import { FluentMdl2CompletedSolid } from '@/assets/icons/order/FluentMdl2CompletedSolid';
import { useTranslation } from 'react-i18next';

export default ({ data }: any) => {
  const { t } = useTranslation();
  const showTextMap = {
    '0': t('待结算'),
    '1': t('已结算'),
  } as any;

  return (
    <div className="flex flex-col justify-center items-center mt-4 ">
      {+data?.status === 1 && (
        <div className="bg-success/20 text-success w-12 h-12 rounded-[100%] flex justify-center items-center">
          <FluentMdl2CompletedSolid width="2.5em" height="2.5em" />
        </div>
      )}
      {+data?.status === 0 && <LineMdCoffeeLoop width="2.5em" height="2.5em" />}

      {
        <span
          className={`text-auxiliaryTextColor text-base mt-2 -[3px] font-bold ${
            data?.status === 1 ? 'text-success' : 'text-error'
          }`}
        >
          {showTextMap[data?.status?.toString()]}
        </span>
      }
    </div>
  );
};
