import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseTopNav from '@/components/base/baseTopNav';
import AiOrderDetailDetailInformation from '@/pages/mine/aiOrderDetail/components/AiOrderDetailDetailInformation';
import AiOrderDetailStatusIcon from '@/pages/mine/aiOrderDetail/components/AiOrderDetailStatusIcon';
import { aiOrderDetailApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';

export default () => {
  const [urlState] = useUrlState<any>({});
  const { id } = urlState || {};

  const { data, loading } = useReq(() => aiOrderDetailApi({ id }), {
    formatResult: (res: any) => {
      return res?.data ?? {};
    },
  }) as any;

  return (
    <div>
      {data && (
        <>
          <div className="my-6">
            <BaseTopNav
              title={
                <div>
                  <span className="text-backContrastColor text-base font-bold">
                    {data?.name}
                  </span>
                </div>
              }
            />
          </div>
          <div className="px-4">
            <AiOrderDetailStatusIcon data={data} />
            <AiOrderDetailDetailInformation data={data} />
          </div>
        </>
      )}

      {loading && (
        <div className="h-[100vh] flex justify-center items-center">
          <BaseLoadingDisplay loading={loading} list={loading ? [] : [1]} />
        </div>
      )}
    </div>
  );
};
