import RenderUtil from '@/utils/RenderUtil';
import { Divider } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';


export default ({ data }: any) => {
  const { t } = useTranslation();

  return (
    <div className="mt-4">
      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('订单号')}</span>
          <span className="text-backContrastColor bg-[transparent] flex items-center">
            {data?.orderNo}
          </span>
        </div>
      </div>
      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
      <div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('创建时间')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.formatDate(data?.createTime,"YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('结算时间')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.formatDate(data?.settlementTime,"YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
      </div>
      <div className="mt-10">
        <div className="titleText text-lg mb-4">{t('成交详情')}</div>
        {data?.completeTime && (
          <div className="flex justify-between mb-2 text-sm">
            <span className="text-auxiliaryTextColor">{t('成交日期')}</span>
            <span className="text-backContrastColor">
              {RenderUtil.formatDate(data?.completeTime,"YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        )}

        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('币对名称')}</span>
          <span className="text-backContrastColor">{data?.symbolName}</span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('投入价格')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.inputPrice, 2)}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('结算价格')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.settlementPrice, 2)}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('盈利价格')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.profitPrice, 2)}
          </span>
        </div>
        <div className="flex justify-between mb-2 text-sm">
          <span className="text-auxiliaryTextColor">{t('盈利率')}</span>
          <span className="text-backContrastColor">
            {RenderUtil.FormatAmount(data?.profitRate, 2)}
          </span>
        </div>
      </div>
    </div>
  );
};
